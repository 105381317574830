import languageMap
  from './form-contact.page.language';

import {
  Component,
  HostBinding,
  Inject,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';

import { HttpClient } from '@angular/common/http';


import {
  ActivatedRoute
} from '@angular/router';

import {
  TooltipService
} from '@teamhive/ngx-tooltip';

import {
  take
} from 'rxjs/operators';

import {
  Country,
  Option,
  Response,
  WH_Option
} from '../../models';

import {
  ResponseService
} from '../../providers';

import {
  formatPhone,
  getCountryOptions,
  getLabelLanguage,
  getModelOption,
  getOptionsDeliveryCountry,
  isCountryCodeSafe,
  isCountryZoneCodeSafe,
  isEmailSafe,
  isFuncSafe,
  isNumberSafe,
  isPhoneSafe,
  isStringSafe,
  isStringSafeNotEmpty
} from '../../app/app.utils';

import {
  _DEFAULT_HOST,
  _DEFAULT_HOST_EU,
  FORM_SUBMISSION_TIMEOUT,
  LANGUAGE_CODE_DEFAULT,
  MESSAGE_MIN_LENGTH,
  OPTIONS_PARCEL_SIZE,
  OPTIONS_SHIPMENT_TYPE,
  OPTIONS_TECHNOLOGY,
  OPTIONS_VOLUME_ESTIMATE,
  OPTIONS_WAREHOUSE_LOCATION,
  REQUIRED_FIELD_PHONE,
  API_WEBSITE_VALIDATOR,
  PREFERED_BRAND_NAMES,
  _ABACPLUS_HOST,
  HOST_CONFIG,
  ABC_OPTIONS_WAREHOUSE_CODES,
  NETWORK_OPTIONS_WAREHOUSE_CODES
} from '../../app/app.const';

@Component({
  selector: 'biz-form-contact-page',
  templateUrl: './form-contact.page.component.html',
  styleUrls: [
    './form-contact.page.component.scss'
  ]
})
export class FormContactPageComponent implements OnInit {
  @HostBinding('class.new')
  public isNew: boolean = false;

  @HostBinding('class.white')
  public whiteTheme: boolean = false;

  @HostBinding('class.horizontal')
  public horizontalLayout: boolean = false;

  public brandName: string = '';
  
  public Jobs: boolean = true;

  @HostBinding('class.landing-page')
  public countryCodeLandingPage: string;

  @ViewChild('inputPhone')
  public inputPhoneRef: ElementRef;

  @ViewChild('textareaMessage')
  public textareaMessageRef: ElementRef;

  public name: string;
  public email: string;
  public phone: string;
  public companyName: string;
  public position: string;
  public consignmentCode: string;
  public industry: string;
  public message: string;

  website: string;
  webStatus: 'valid' | 'invalid' | 'null' |'unverified' = 'null';
  loading = false;
  isVerified = false;

  public weight : number;
  public deliveryAddress : string;
  public pickUpAddress : string;
  
  public consignmentCodeShow: boolean = true;
  public contactBoxShow: boolean = true;
  public contactBoxShowEu: boolean = false;

  public countryOptions: {
    preferred: Country[],
    other: Country[]
  };
  public countryOptionActive: Country;

  public pickUpCountryOptions: {
    preferred: Country[],
    other: Country[]
  };
  public pickUpCountryOptionActive: Country;

  public deliveryCountryOptions: {
    preferred: Country[],
    other: Country[]
  };
  public deliveryCountryOptionActive: Country;

  public hasCod: boolean = false;
  public hasDelivery: boolean = false;
  public hasFulfillment: boolean = false;
  public hasReturns: boolean = false;
  public hasParcel: boolean = false;

  public imageLoaded: boolean = false;

  public ipAddress: string;
  public ipCountryCode: string;

  public languageCode: string;

  public optionsDeliveryCountry: Option[] = (
    getOptionsDeliveryCountry()
  );
  public optionsParcelSize: Option[] = (
    OPTIONS_PARCEL_SIZE
  );
  public optionsShipmentType: Option[] = (
    OPTIONS_SHIPMENT_TYPE
  );
  public optionsTechnology: Option[] = (
    OPTIONS_TECHNOLOGY
  );
  public optionsVolumeEstimate: Option[] = (
    OPTIONS_VOLUME_ESTIMATE
  );
  public optionsWarehouseLocation: WH_Option[] = (
    OPTIONS_WAREHOUSE_LOCATION
  );

  public deliveryCountryCodes: string[] = [];
  public parcelSize: string;
  public technologies: boolean[];
  public volumeEstimate: string;
  public warehouseLocation: string;
  public shipmentType: string;

  public declaration: string;
  public declarationAccepted: boolean = false;

  public error: string;

  public sendAttempted: boolean = false;
  public sending: boolean = false;
  public success: boolean = false;

  public tooltipButtonTimeout;

  constructor(
    @Inject('Window')
    private window: Window,

    private route: ActivatedRoute,
    private responseService: ResponseService,
    private tooltipService: TooltipService,
    private http: HttpClient
  ) { }

  public get countryOptionActiveCode() {
    return (
      this.countryOptionActive ?
        (
          this.countryOptionActive
              .code
        ) :
        undefined
    );
  }

  
  public get pickUpCountryOptionActiveCode() {
    return (
      this.pickUpCountryOptionActive ?
        (
          this.pickUpCountryOptionActive
              .code
        ) :
        undefined
    );
  }

  public get deliveryCountryOptionActiveCode() {
    return (
      this.deliveryCountryOptionActive ?
        (
          this.deliveryCountryOptionActive
              .code
        ) :
        undefined
    );
  }

  public get countryOptionActiveName() {
    return (
      this.countryOptionActive ?
        (
          this.countryOptionActive
              .name
        ) :
        null
    );
  }

  public get pickUpCountryOptionActiveName() {
    return (
      this.pickUpCountryOptionActive ?
        (
          this.pickUpCountryOptionActive
              .name
        ) :
        null
    );
  }

  public get deliveryCountryOptionActiveName() {
    return (
      this.deliveryCountryOptionActive ?
        (
          this.deliveryCountryOptionActive
              .name
        ) :
        null
    );
  }

  public get deliveryCountryNames() {
    return (
      this.deliveryCountryCodes
          .map(
            deliveryCountryCode => (
              this
                .getLabelLanguage(
                  deliveryCountryCode
                )
                .replace(
                  '*',
                  ''
                )
            )
          )
          .sort(
            (
              deliveryCountryNameA,
              deliveryCountryNameB
            ) => (
              deliveryCountryNameA
                .localeCompare(
                  deliveryCountryNameB
                )
            )
          )
          .join(
            ', '
          )
    );
  }

  public get deliveryCountryZoneCode() {
    return (
      this.deliveryCountryCodes
          .find(
            deliveryCode => (
              isCountryZoneCodeSafe(
                deliveryCode
              )
            )
          )
    );
  }

  public get hasServices() {
    return (
      this.hasDelivery ||
      this.hasFulfillment ||
      this.hasReturns
    );
  }

  public get hasDeliveryCountryZone() {
    return (
      !!this.deliveryCountryZoneCode
    );
  }

  public get isEmptyPhone() {
    return (
      !(
        isStringSafe(
          this.phone
        )
      ) ||
      (
        (
          this.phone
              .length
        ) ===
        0
      )
    );
  }

  public get isRequiredPhone() {
    return (
      REQUIRED_FIELD_PHONE
    );
  }

  public get isValidDeliveryCountryCodes() {
    return (
      (
        this.deliveryCountryCodes
            .filter(
              deliveryCountryCode => (
                isStringSafe(
                  deliveryCountryCode
                )
              )
            )
            .length
      ) >
      0
    );
  }

  public get isValidEmail() {
    return (
      isEmailSafe(
        this.email
      )
    );
  }

  public get isValidMessage() {
    return (
      (
        isStringSafe(
          this.message
        )
      ) &&
      (
        (
          this.message
              .length
        ) >=
        MESSAGE_MIN_LENGTH
      )
    );
  }

  public get isValidIpAddress() {
    return (
      (
        isStringSafe(
          this.ipAddress
        )
      ) &&
      (
        (
          this.ipAddress
              .length
        ) >=
        7
      ) &&
      (
        (
          this.ipAddress
              .length
        ) <=
        15
      )
    );
  }

  public get isValidName() {
    return (
      (
        isStringSafe(
          this.name
        )
      ) &&
      (
        (
          this.name
              .length
        ) >=
        4
      )
    );
  }

  public get isDeliveryAddressSafe() {
    return (
      (
        isStringSafe(
          this.deliveryAddress
        )
      ) &&
      (
        (
          this.deliveryAddress
              .length
        ) >=
        4
      )
    );
  }

  public get isPickUpAddressSafe() {
    return (
      (
        isStringSafe(
          this.pickUpAddress
        )
      ) &&
      (
        (
          this.pickUpAddress
              .length
        ) >=
        4
      )
    );
  }

  public get isValidParcelSize() {
    return (
      isStringSafe(
        this.parcelSize
      )
    );
  }
  

  public get isValidPhone() {
    return (
      isPhoneSafe(
        this.phone
      )
    );
  }

  public get isValidWeight() {
    return (
      isNumberSafe(
        Number(this.weight)
      ) &&
      Number(this.weight) > 0
    );
  }
  

  public get isValidServiceType() {
    return (
      isStringSafe(
        this.shipmentType
      )
    );
  }

  public get isDeliveryCountrySafe() {
    return (
      isStringSafe(
        this.deliveryCountryOptionActiveName
      )
    );
  }

  public get isPickUpCountrySafe() {
    return (
      isStringSafe(
        this.pickUpCountryOptionActiveName
      )
    );
  }

  public get isValidPhoneEmpty() {
    return (
      !this.isRequiredPhone &&
      this.isEmptyPhone
    );
  }

  public get isValidVolumeEstimate() {
    return (
      isStringSafe(
        this.volumeEstimate
      )
    );
  }

  public get isValid() {
    return (
      this.isValidExclMessage &&
      this.isValidMessage
    );
  }

  public get isValidExclMessage() {
    return (
      this.isValidEmail &&
      this.isValidName &&
      (
        this.isValidPhone ||
        this.isValidPhoneEmpty
      ) &&(
        (
          !(
            this.hasDelivery ||
            this.hasFulfillment
          )
        ) ||
        this.isValidDeliveryCountryCodes
      ) &&
      (
        !this.hasServices ||
        (
          this.isValidParcelSize &&
          this.isValidVolumeEstimate
        )
      ) &&
      (
        !this.hasParcel || (
          this.isValidWeight &&
          this.isValidServiceType &&
          this.isDeliveryAddressSafe &&
          this.isPickUpAddressSafe &&
          this.isDeliveryCountrySafe &&
          this.isPickUpCountrySafe

      )
      )
    );
  }

  public get messageDeclaration() {
    return (
      this.getLabelLanguage(
        'tooltip-declaration'
      )
    );
  }

  public get messageIpAddress() {
    return (
      this.getLabelLanguage(
        'tooltip-ip-address'
      )
    );
  }

  public get messageLengthLabel() {
    return (
      `${(
        this.getLabelLanguage(
          'message-characters-min'
        )
      )} / ${(
        (
          this.messageLengthMin ===
          0
        ) ?
          `${MESSAGE_MIN_LENGTH} ${(
            this.getLabelLanguage(
              'message-characters-reached'
            )
          )}` :
          `${this.messageLengthMin} ${(
            this.getLabelLanguage(
              'message-characters-left'
            )
          )}`
      )}`
    );
  }

  public get messageLengthMin() {
    return (
      (
        this.message ?
          (
            Math
              .max(
                0,
                (
                  MESSAGE_MIN_LENGTH -
                  (
                    this.message
                        .length
                  )
                )
              )
          ) :
          MESSAGE_MIN_LENGTH
      )
    );
  }

  public get messageValidation() {
    return (
      this.getLabelLanguage(
        'tooltip-validation'
      )
    );
  }

  public get messageValidationInclPhone() {
    return (
      this.getLabelLanguage(
        'tooltip-validation-incl-phone'
      )
    );
  }

  public get messageValidationMessage() {
    return (
      this.getLabelLanguage(
        'tooltip-validation-message'
      )
    );
  }

  public get optionsTechnologyServices() {
    if (!this.hasServices) {
      return [];
    }

    return [
      ...(
        (
          this.hasDelivery ||
          this.hasFulfillment
        ) ?
          [
            this.optionsTechnology[0]
          ] :
          []
      ),
      this.optionsTechnology[1],
      ...(
        (
          this.hasFulfillment ||
          this.hasReturns
        ) ?
          [
            this.optionsTechnology[2]
          ] :
          []
      )
    ];
  }

  public get optionParcelSizeLabel() {
    if (!this.parcelSize) {
      return null;
    }

    const optionParcelSize = (
      this.optionsParcelSize
          .find(
            optionParcelSizeEach => (
              (
                optionParcelSizeEach
                  .value
              ) ===
              this.parcelSize
            )
          )
    );

    if (!optionParcelSize) {
      return null;
    }

    return (
      optionParcelSize
        .label
    );
  }

  public get optionVolumeEstimateLabel() {
    if (!this.volumeEstimate) {
      return null;
    }

    const optionVolumeEstimate = (
      this.optionsVolumeEstimate
          .find(
            optionVolumeEstimateEach => (
              (
                optionVolumeEstimateEach
                  .value
              ) ===
              this.volumeEstimate
            )
          )
    );

    if (!optionVolumeEstimate) {
      return null;
    }

    return (
      optionVolumeEstimate
        .label
    );
  }



  public get optionWarehouseLocationLabel() {
    if (!this.warehouseLocation) {
      return null;
    }

    const optionWarehouseLocation = (
      this.optionsWarehouseLocation
          .find(
            optionWarehouseLocationEach => (
              (
                optionWarehouseLocationEach
                  .value
              ) ===
              this.warehouseLocation
            )
          )
    );

    if (!optionWarehouseLocation) {
      return null;
    }

    return (
      optionWarehouseLocation
        .label
    );
  }

  public get tooltipEmail() {
    return (
      this.getLabelLanguage(
        'email-tooltip'
      )
    );
  }

  public get tooltipName() {
    return (
      this.getLabelLanguage(
        'name-tooltip'
      )
    );
  }

  public get tooltipPhone() {
    return (
      this.getLabelLanguage(
        this.isRequiredPhone ?
          'phone-tooltip-required' :
          'phone-tooltip'
      )
    );
  }
  
  public ngOnInit() {
    this.initQueryParams();
  }

  public getLabelLanguage(
    key: string
  ) {
    return (
      getLabelLanguage(
        key,
        this.languageCode,
        languageMap,
        this.brandName
      )
    );
  }

  public hasOptionTechnologyServices(
    option: Option
  ) {
    return (
      this.optionsTechnologyServices
          .map(
            optionServices => (
              optionServices
                .value
            )
          )
          .includes(
            option
              .value
          )
    );
  }

  public isDeliveryCountryZone(
    code: string
  ) {
    return (
      (
        isCountryZoneCodeSafe(
          code
        )
      ) &&
      (
        this.deliveryCountryCodes
            .includes(
              code
            )
      )
    );
  }

  public onImageLoaded() {
    this.imageLoaded = true;
  }

  public async onSend() {
    if (
      !(
        this.isValid &&
        this.isValidIpAddress &&
        this.declarationAccepted
      )
    ) {
      const tooltip = (
        this.getTooltip(
          'button-send'
        )
      );

      this.showTooltipExcl(
        tooltip
      );

      this.tooltipButtonTimeout = (
        setTimeout(
          () => (
            tooltip.hide()
          ),
          2 * 1000
        )
      );

      return (
        this.sendAttempted = true
      );
    }

    if (this.sending) {
      return false;
    }

    this.sending = true;

    
    const isBrandBiz = this.brandName === 'biz';

    const hostDetails = isBrandBiz ? HOST_CONFIG['DEFAULT'] : HOST_CONFIG[this.brandName.toUpperCase()];


    const response = new Response({
      name: this.name,
      email: this.email,
      phone: this.phone,
      companyName: this.companyName,
      position: this.position,
      countryCode: this.countryOptionActiveCode,
      consignmentCode: this.consignmentCode,
      industry: this.industry,
      ipAddress: this.ipAddress,
      ipCountryCode: this.ipCountryCode,
      languageCode: this.languageCode,
      message: this.message,
      hasCod: this.hasCod,
      hasDelivery: this.hasDelivery,
      hasFulfillment: this.hasFulfillment,
      website: this.website,
      isVerified: this.isVerified,
      hasParcel: this.hasParcel,
      weight: this.weight,
      deliveryAddress: this.deliveryAddress,
      deliveryCountryCode: this.deliveryCountryOptionActiveCode,
      pickUpAddress: this.pickUpAddress,
      pickUpCountryCode: this.pickUpCountryOptionActiveCode,
      hasReturns: this.hasReturns,
      deliveryCountryCodes: this.deliveryCountryCodes,
      parcelSize: this.parcelSize,
      shipmentType: this.shipmentType,
      technologies: this.optionsTechnology
        .filter((option, index) => this.technologies[index])
        .map(option => option.value),
      volumeEstimate: this.volumeEstimate,
      warehouseLocation: this.warehouseLocation,
      type: 'contact',
      partnerRedirect: {
        weight: this.weight,  
        deliveryAddress: this.deliveryAddress,
        deliveryCountryCode: this.deliveryCountryOptionActiveCode,
        pickUpAddress: this.pickUpAddress,
        pickUpCountryCode: this.pickUpCountryOptionActiveCode,
        shipmentType: this.shipmentType,
      },
      host: this.contactBoxShowEu ? hostDetails.hostEu : hostDetails.host,
      brandName: this.brandName,
    });
    

    const {
      failureCount,
      errors
    } = (
      await (
        this.responseService
            .upsertResponse(
              response
            )
      )
    );

    this.window
        .scrollTo({
          top: 0,
          behavior: 'smooth'
        });

    if (
      failureCount >
      0
    ) {
      this.error = (
        this.getLabelLanguage(
          'form-failed'
        )
      );

      this.sending = false;

      return (
        console.error(
          errors[0]
        )
      );
    }

    this.success = true;
    this.sending = false;

    try {
      if (
        (
          (<any>this.window)
            .dataLayer
        ) &&
        (
          isFuncSafe(
            (<any>this.window)
              .dataLayer
              .push
          )
        )
      ) {
        (<any>this.window)
          .dataLayer
          .push({
            event: 'iframe_form_submission'
          });
      }
      else {
        console.error(
          'Window data layer not available, GTM has possibly not loaded'
        );
      }
    }
    catch (ex) {
      console.error(
        ex.message
      );
    }

    setTimeout(
      () => (
        this.reset()
      ),
      FORM_SUBMISSION_TIMEOUT *
      1000
    );
  }

  public async onValue(
    key: string,
    value: Option|string|boolean,
    index?: number
  ) {
    try {
      if (
        isNumberSafe(
          index
        )
      ) {
        if (
          Array
            .isArray(
              this[key]
            )
        ) {
          this[key][index] = value;
        }

        return;
      }

      if (
        isStringSafe(
          value
        )
      ) {
        const valueStr = (
          <string>value
        );

        if (
          (
            key ===
            'name'
          )
        ) {
          if (
            (
              valueStr
                .length
            ) >
            0
          ) {
            const tooltip = (
              this.getTooltip(
                'input-name'
              )
            );

            this[key] = valueStr;

            if (this.isValidName) {
              tooltip
                .hide();
            }
            else {
              this.showTooltipExcl(
                  tooltip
                );
            }

            return true;
          }
        }

        if (
          key ===
          'email'
        ) {
          if (
            (
              valueStr
                .length
            ) >
            0
          ) {
            const tooltip = (
              this.getTooltip(
                'input-email'
              )
            );

            this[key] = valueStr;

            if (this.isValidEmail) {
              tooltip.hide();
            }
            else {
              this.showTooltipExcl(
                tooltip
              );
            }

            return true;
          }
        }

        if (
          key ===
          'phone'
        ) {
          const tooltip = (
            this.getTooltip(
              'input-phone'
            )
          );

          this[key] = (
            formatPhone(
              valueStr
            )
          );

          if (
            this.isValidPhone ||
            (
              (
                valueStr
                  .length
              ) ===
              0
            )
          ) {
            tooltip.hide();
          }
          else {
            this.showTooltipExcl(
              tooltip
            );
          }

          return (
            this.inputPhoneRef
                .nativeElement
                .value = (
                  this[key]
                )
          );
        }
      }

      if (
        Array
          .isArray(
            this[key]
          )
      ) {
        if (
          Array
            .isArray(
              value
            )
        ) {
          if (
            key ===
            'deliveryCountryCodes'
          ) {
            const deliveryCodeZone = (
              this.deliveryCountryZoneCode
            );

            this[key] = value;
  
            if (this.hasDeliveryCountryZone) {
              return (
                this[key] = (
                  this[key]
                    .filter(
                      code => (
                        (
                          (
                            deliveryCodeZone &&
                            (
                              deliveryCodeZone ===
                              code
                            )
                          ) ||
                          (
                            !deliveryCodeZone &&
                            (
                              isCountryZoneCodeSafe(
                                code
                              )
                            )
                          )
                        )
                      )
                    )
                )
              )
            };
          }

          return (
            this[key] = value
          );
        }
        
        if (
          !(
            this[key]
              .includes(
                value
              )
          )
        ) {
          return (
            this[key]
              .push(
                value
              )
          );
        }
      }

      this[key] = value;
    }
    catch (ex) {
      // Do nothing
    }
  }

  public setCountryOptionActive(
    code: string
  ) {
    this.countryOptionActive = (
      this.getCountryOption(
        code
      )
    );
  }

  public setPickUpCountryOptionActive(
    code: string
  ) {
    this.pickUpCountryOptionActive = (
      this.getCountryOption(
        code
      )
    );
  }

  
  public setDeliveryCountryOptionActive(
    code: string
  ) {
    this.deliveryCountryOptionActive = (
      this.getCountryOption(
        code
      )
    );
  }

  public setHasDelivery(
    hasDelivery: boolean
  ) {
    this.hasDelivery = hasDelivery;

    if (!this.hasDelivery) {
      this.hasCod = false;


      if (!this.hasFulfillment) {
        this.deliveryCountryCodes = [];
        this.technologies[0] = false;
      }

      if (!this.hasServices) {
        this.parcelSize = undefined;
        this.volumeEstimate = undefined;
        this.technologies[1] = false;
      }
      
      const tooltipButton = (
        this.getTooltip(
          'button-send'
        )
      );

      tooltipButton
        .hide();
    }
    if (this.hasParcel){
      this.hasParcel= false;
    }
  }

  public setHasFulfillment(
    hasFulfillment: boolean
  ) {
    this.hasFulfillment = hasFulfillment;

    if (!this.hasFulfillment) {
      if (!this.hasDelivery) {
        this.deliveryCountryCodes = [];
        this.technologies[0] = false;
      }

      if (!this.hasReturns) {
        this.technologies[2] = false;
        this.industry = undefined;
      }

      if (!this.hasServices) {
        this.parcelSize = undefined;
        this.volumeEstimate = undefined;
        this.technologies[1] = false;
      }

      this.warehouseLocation = undefined;
      
      const tooltipButton = (
        this.getTooltip(
          'button-send'
        )
      );
      tooltipButton
        .hide();
    }
    if (this.hasParcel){
      this.hasParcel= false;
    }
  }

  public setHasReturns(
    hasReturns: boolean
  ) {
    this.hasReturns = hasReturns;

    if (!this.hasReturns) {
      if (!this.hasFulfillment) {
        this.technologies[2] = false;
        this.industry = undefined;
      }

      if (!this.hasServices) {
        this.parcelSize = undefined;
        this.volumeEstimate = undefined;
        this.technologies[1] = false;
      }
      
      const tooltipButton = (
        this.getTooltip(
          'button-send'
        )
      );

      tooltipButton
        .hide();
    }
  }
  public setHasParcel(
    hasParcel: boolean
  ) {
    this.hasParcel = hasParcel;
    if (!this.hasParcel) {
      const tooltipButton = (
        this.getTooltip(
          'button-send'
        )
      );
      tooltipButton
        .hide();
    }
  }

  public get optionShipmentTypeLabel() {
    if (!this.shipmentType) {
      return null;
    }
    const optionShipmentType = (
      this.optionsShipmentType
          .find(
            optionShipmentTypeEach => (
              (
                optionShipmentTypeEach
                  .value
              ) ===
              this.shipmentType
            )
          )
    );
    if (!optionShipmentType) {
      return null;
    }
    return (
      optionShipmentType
        .label
    );
  }



  private getCountryOption(code): Country {
    return (
      getModelOption(
        this.countryOptions,
        code
      )
    );
  }

  private getTooltip(
    name: string
  ) {
    const group = (
      this.tooltipService
          .getGroup(
            name
          )
    );

    if (!group) {
      return null;
    }

    const res = (
      group
        .values()
        .next()
    );
    
    if (!res) {
      return null;
    }

    return (
      res.value
    );
  }

  private initCountryOptions() {
    this.countryOptions = (
      getCountryOptions(
        this.languageCode
      )
    );
  }

  private initPickUpCountryOptions() {
    this.pickUpCountryOptions = (
      getCountryOptions(
        
      )
    );
  }

  private initDeliveryCountryOptions() {
    this.deliveryCountryOptions = (
      getCountryOptions(
        
      )
    );
  }

  private initShipmentType() {
    this.optionsShipmentType = (
      this.optionsShipmentType
          .map(
            optionShipmentType => ({
              ...optionShipmentType,
              label: (
                this.getLabelLanguage(
                  optionShipmentType
                    .value
                )
              )
            })
          )
    );
  }

  private initQueryParams() {
    this.route
        .queryParams
        .pipe(
          take(
            1
          )
        )
        .subscribe(
          async queryParams => {
            this.hasDelivery = (
              (
                queryParams
                  .delivery
              ) ===
              'true'
            );

            this.hasFulfillment = (
              (
                queryParams
                  .fulfillment
              ) ===
              'true'
            );

            this.isNew = (
              (
                queryParams
                  .isNew
              ) ===
              'true'
            );
            this.whiteTheme = (
              (
                queryParams
                  .theme
              ) ===
              'white'
            );
            this.horizontalLayout = (
              (
                queryParams
                  .layout
              ) ===
              'horizontal'
            );
            this.Jobs = (
              (
                queryParams
                  .hideJobs
              ) ===
              'true'
            );

            this.countryCodeLandingPage = (
              (
                isCountryCodeSafe(
                  queryParams
                    .landingPage
                )
              ) ?
                (
                  queryParams
                    .landingPage
                    .toLowerCase()
                ) :
                undefined
            );

            this.languageCode = (
              (
                queryParams
                  .language
              ) ?
                (
                  queryParams
                    .language
                ) :
                LANGUAGE_CODE_DEFAULT
            );

            this.name = (
              queryParams
                .name
            );

            this.email = (
              queryParams
                .email
            );

            this.phone = (
              queryParams
                .phone
            );

            this.companyName = (
              queryParams
                .companyName
            );

            this.website = (
              queryParams
                .website
            );

            this.consignmentCode = (
              queryParams
                .consignmentCode
            );

            this.consignmentCodeShow = (
              (
                queryParams
                  .consignmentCodeShow
              ) !==
              'false'
            );

            this.contactBoxShow = (
              (
                queryParams
                  .contactBoxShow
              ) !==
              'false'
            );

            this.contactBoxShowEu = (
              (
                queryParams
                  .isEu
              ) ===
              'true'
            );
           
            this.brandName = queryParams.brandName || 'biz';
            if (!PREFERED_BRAND_NAMES.includes(this.brandName)){
              this.brandName = 'biz';
            }

            this.initCountryOptions();
            this.initPickUpCountryOptions();
            this.initDeliveryCountryOptions();
            this.initDeclaration();
            this.initDeliveryCountries();
            this.initParcelSizes();
            this.initTechnologies();
            this.initWarehouseLocations();
            this.initShipmentType();

            await (
              this.initIpData()
            )
          }
        );
  }

  private initDeclaration() {
    this.declaration = (
      this.getLabelLanguage(
        'declaration'
      )
    );
  }

  private initDeliveryCountries() {
    this.optionsDeliveryCountry = (
      this.optionsDeliveryCountry
        .map(
          optionDeliveryCountry => ({
            ...optionDeliveryCountry,
            label: (
              this.getLabelLanguage(
                optionDeliveryCountry
                  .value
              )
            )
          })
        )
        .sort(
          (
            optionDeliveryCountryA,
            optionDeliveryCountryB
          ) => (
            optionDeliveryCountryA
              .label
              .localeCompare(
                optionDeliveryCountryB
                  .label
              )
          )
        )
    );
  }

  private async initIpData() {
    try {
      this.ipAddress = (
        await (
          this.responseService
              .getIpAddress()
        )
      );

      if (
        isStringSafeNotEmpty(
          this.ipAddress
        )
      ) {
        this.ipCountryCode = (
          await (
            this.responseService
                .getIpCountryCode(
                  this.ipAddress
                )
          )
        );
      }
    }
    catch (ex) {
      console.error(
        ex.message
      );
    }
  }

  private initParcelSizes() {
    this.optionsParcelSize = (
      this.optionsParcelSize
          .map(
            optionParcelSize => ({
              ...optionParcelSize,
              label: (
                this.getLabelLanguage(
                  optionParcelSize
                    .value
                )
              )
            })
          )
    );
  }

  private initTechnologies() {
    this.optionsTechnology = (
      this.optionsTechnology
          .map(
            optionTechnology => ({
              ...optionTechnology,
              label: (
                this.getLabelLanguage(
                  optionTechnology
                    .value
                )
              )
            })
          )
    );

    this.technologies = (
      this.optionsTechnology
          .map(
            () => (
              false
            )
          )
    );
  }

  private initWarehouseLocations() {
    const filterCodes = (
      this.brandName === 'biz' ?
        NETWORK_OPTIONS_WAREHOUSE_CODES :
        ABC_OPTIONS_WAREHOUSE_CODES
    );

    this.optionsWarehouseLocation = (
      this.optionsWarehouseLocation
        .filter(warehouseLocation => (
            filterCodes.includes(
              warehouseLocation.countryCode
            )
          )
        )
        .map(
          warehouseLocation => ({
            ...warehouseLocation,
            label: (
              this.getLabelLanguage(
                warehouseLocation
                  .value
              )
            )
          })
        )
    );
  }

  private showTooltipExcl(
    tooltip
  ) {
    this.unsetTooltipButtonTimeout();

    Array
      .from(
        this.tooltipService
            .instances
            .entries()
      )
      .map(
        tuple => (
          tuple[1]
        )
      )
      .forEach(
        tooltipEach => {
          if (
            (
              tooltipEach
                .id
            ) !==
            (
              tooltip
                .id
            )
          ) {
            tooltipEach
              .hide();
          }
        }
      );

    tooltip.show();
  }

  private unsetTooltipButtonTimeout() {
    if (
      this.tooltipButtonTimeout
    ) {
      clearTimeout(
        this.tooltipButtonTimeout
      );

      this.tooltipButtonTimeout = (
        undefined
      );
    }
  }

  private reset() {
    this.name = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.companyName = undefined;
    this.consignmentCode = undefined;
    this.industry = undefined;
    this.message = undefined;

    this.website= undefined;
    this.webStatus= 'null';
    this.loading = false;
    this.isVerified = false;
    this.weight = undefined;
    this.deliveryAddress = undefined;
    this.pickUpAddress = undefined;

    this.hasCod = false;
    this.hasDelivery = false;
    this.hasFulfillment = false;
    this.hasReturns = false;
    this.hasParcel = false;

    this.shipmentType = undefined;

    this.deliveryCountryCodes = [];
    this.parcelSize = undefined;
    this.volumeEstimate = undefined;
    this.warehouseLocation = undefined;
    
    this.error = undefined;

    this.declarationAccepted = false;
    this.imageLoaded = false;
    this.sendAttempted = false;

    this.initTechnologies();

    this.unsetTooltipButtonTimeout();

    this.tooltipService
        .hideAll();

    this.success = false;
  }


  getCareerLink(): string {
    if (this.languageCode === 'en' || this.languageCode === 'hr'|| this.languageCode === 'pt' || this.languageCode === 'sk' || this.languageCode === 'sl' || this.languageCode === 'cs') {
      return `${_DEFAULT_HOST_EU}/featured/career/`;
    } else {
      return `${_DEFAULT_HOST_EU}/${this.languageCode}/${this.getLabelLanguage('featured')}/career/`;
    }
  }
  
  onBlur(website: string) {
    // Reset status & isVerified
    this.isVerified = false;
    this.webStatus = 'null';
    if(website === '') {
      this.webStatus = 'null';
      return; 
    }
    // Check if the website format is valid
    if (!this.isValidFormat(website)) {
      this.webStatus = 'invalid';
      return;
    }
   
    this.loading = true;

    const url = (
      API_WEBSITE_VALIDATOR
        .replace(
          '{url}',
          (
            encodeURIComponent(
              website
            )
          )
        )
    );

    this.http.get<ApiResponse>(url).subscribe(response => {
      if (response.webStatus === "valid"){
        this.loading = false;
        this.webStatus = 'valid';
        this.isVerified = true;
      }
      else{
        this.loading = false;
        this.webStatus = 'unverified';
        this.isVerified = false;
      }
    }, error => {
      console.error(error);
      this.webStatus = 'unverified';
      this.isVerified = true;
      this.loading = false;
    });

    
  }
  isValidFormat(website: string): boolean {
    const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlRegex.test(website);
  }
}
interface ApiResponse {
  webStatus: string;
}