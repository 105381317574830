import {
  Component,
  AfterViewInit,
  HostBinding,
  NgZone,
  Inject,
  OnInit,
  OnDestroy
} from '@angular/core';

import {
  DOCUMENT
} from '@angular/common';

import {
  ActivatedRoute
} from '@angular/router';

import {
  Subscription
} from 'rxjs';

import {
  getLanguageCodesSupported,
  registerLocale
} from './app.utils';

import {
  LANGUAGE_CODE_DEFAULT,
  PREFERED_BRAND_NAMES
} from './app.const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class.new')
  public isNew: boolean = false;

  @HostBinding('class.no-bg')
  public hideBackground: boolean = false;

  @HostBinding('class.white')
  public whiteTheme: boolean = false;

  @HostBinding('class') 
  public currentClass: string = '';
  
  public brandName: string = '';

  
  @HostBinding('class.horizontal')
  public horizontalLayout: boolean = false;
  
  public languageCode: string;

  private queryParamsSub: Subscription;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private zone: NgZone,
    private route: ActivatedRoute
  ) {
    this.registerLocales();
  }

  public ngOnInit() {
    this.initQueryParamsSub();
  }

  public ngOnDestroy() {
    if (this.queryParamsSub) {
      this.queryParamsSub
          .unsubscribe();

      this.queryParamsSub = (
        undefined
      );
    }
  }

  public ngAfterViewInit() {
    this.setNewClassName();
    this.setWhiteClassName();
    this.setHorizontalClassName();
    this.setNoBgClassName();
    this.setBrandNameClass(this.brandName);
  }

  private initQueryParamsSub() {
    this.queryParamsSub = (
      this.route
          .queryParams
          .subscribe(
            queryParams => {
              this.languageCode = (
                (
                  queryParams
                    .language
                ) ?
                  (
                    queryParams
                      .language
                  ) :
                  LANGUAGE_CODE_DEFAULT
              );

              this.isNew = (
                (
                  queryParams
                    .isNew
                ) ===
                'true'
              );
              
              this.whiteTheme = (
                (
                  queryParams
                    .theme
                ) ===
                'white'
              );
              
              this.horizontalLayout = (
                (
                  queryParams
                    .layout
                ) ===
                'horizontal'
              );

              this.hideBackground = (
                (
                  queryParams
                    .hideBackground
                ) ===
                'true'
              );

              this.brandName = queryParams.brandName || 'biz';
              if (!PREFERED_BRAND_NAMES.includes(this.brandName)) {
                this.brandName = 'biz';
              }

              this.setNewClassName();
              this.setWhiteClassName();
              this.setHorizontalClassName();
              this.setNoBgClassName();
              this.setBrandNameClass(this.brandName);
            }
          )
    );
  }

  private registerLocales() {
    const languageCodes = (
      getLanguageCodesSupported()
    );

    languageCodes
      .filter(
        languageCode => (
          !(
            languageCode
              .startsWith(
                'de-'
              )
          )
        )
      )
      .forEach(
        languageCode => (
          registerLocale(
            languageCode
          )
        )
      );
  }

  private setNewClassName() {
    this.zone
        .runOutsideAngular(() => {
          if (
            this.document
                .body
          ) {
            if (
              this.isNew &&
              (
                !(
                  this.document
                      .body
                      .classList
                      .contains(
                        'new'
                      )
                )
              )
            ) {
              this.document
                  .body
                  .classList
                  .add(
                    'new'
                  );
            }
            else if (
              !this.isNew &&
              (
                this.document
                    .body
                    .classList
                    .contains(
                      'new'
                    )
              )
            ) {
              this.document
                  .body
                  .classList
                  .remove(
                    'new'
                  );
            }
          }
        });
  }

  private setWhiteClassName() {
    this.zone
        .runOutsideAngular(() => {
          if (
            this.document
                .body
          ) {
            if (
              this.whiteTheme &&
              (
                !(
                  this.document
                      .body
                      .classList
                      .contains(
                        'white'
                      )
                )
              )
            ) {
              this.document
                  .body
                  .classList
                  .add(
                    'white'
                  );
            }
            else if (
              !this.whiteTheme &&
              (
                this.document
                    .body
                    .classList
                    .contains(
                      'white'
                    )
              )
            ) {
              this.document
                  .body
                  .classList
                  .remove(
                    'white'
                  );
            }
          }
        });
  }

  private setHorizontalClassName() {
    this.zone
        .runOutsideAngular(() => {
          if (
            this.document
                .body
          ) {
            if (
              this.horizontalLayout &&
              (
                !(
                  this.document
                      .body
                      .classList
                      .contains(
                        'horizontal'
                      )
                )
              )
            ) {
              this.document
                  .body
                  .classList
                  .add(
                    'horizontal'
                  );
            }
            else if (
              !this.horizontalLayout &&
              (
                this.document
                    .body
                    .classList
                    .contains(
                      'horizontal'
                    )
              )
            ) {
              this.document
                  .body
                  .classList
                  .remove(
                    'horizontal'
                  );
            }
          }
        });
  }

  private setBrandNameClass(brandName: string) {
    this.zone
        .runOutsideAngular(() => {
          if (
            this.document
                .body
          ) {
              if (
                this.currentClass && 
                this.currentClass !== brandName && 
                this.document
                .body.classList
                .contains(this.currentClass)) 
                {
                this.document
                .body.classList
                .remove(this.currentClass);
                }
              this.currentClass = brandName;
              if (
                !this.document
                .body.classList
                .contains(this.currentClass)
              ) {
                this.document
                .body.classList
                .add(this.currentClass);
              }
            }
        });
  }

  private setNoBgClassName() {
    this.zone
        .runOutsideAngular(() => {
          if (
            this.document
                .body
          ) {
            if (
              this.hideBackground &&
              (
                !(
                  this.document
                      .body
                      .classList
                      .contains(
                        'no-bg'
                      )
                )
              )
            ) {
              this.document
                  .body
                  .classList
                  .add(
                    'no-bg'
                  );
            }
            else if (
              !this.hideBackground &&
              (
                this.document
                    .body
                    .classList
                    .contains(
                      'no-bg'
                    )
              )
            ) {
              this.document
                  .body
                  .classList
                  .remove(
                    'no-bg'
                  );
            }
          }
        });
  }
}
