import {
  defaultHost,
  defaultHostEu,
  formsServerUrl,
  abcplusHost
} from '../environments/environment'

export const _DEFAULT_HOST = defaultHost;
export const _DEFAULT_HOST_EU = defaultHostEu;
export const _ABACPLUS_HOST = abcplusHost;
export const _FORMS_SERVER_URL = formsServerUrl;

export const API_URL_IP_ADDRESS = (
  `https://api.ipify.org?format=json`
);
export const API_URL_IP_GEOLOCATION = (
  `${_FORMS_SERVER_URL}api/geolocation/{ipAddress}`
);
export const API_WEBSITE_VALIDATOR = (
  `${_FORMS_SERVER_URL}api/checkwebsite?url={url}`
);

export const COUNTRY_CODES_SUPPORTED = [
  'at',
  'bg',
  'cz',
  'de',
  'es',
  'fr',
  'gr',
  'hr',
  'hu',
  'it',
  'pl',
  'pt',
  'ro',
  'sk',
  'si',
  'be',
  'lu',
  'nl'
];

export const COUNTRY_ZONE_MAP = {
  'eu': {
    'en': '*European Union',
    'el': '*Ευρωπαϊκή Ένωση',
    'bg': '*В Европейския съюз',
    'fr': '*Union européenne',
    'de': '*Europäische Union',
    'de-at': '*Europäische Union',
    'es': '*Unión Europea',
    'it': '*Unione Europea',
    'hr': '*Europska unija',
    'hu': '*Európai Unió',
    'pl': '*Unia Europejska',
    'pt': '*União Europeia',
    'ro': '*Uniunea Europeană',
    'ru': '*Европейский Союз',
    'sk': '*Európska únia',
    'sl': '*Evropska unija',
    'cs': '*Evropská unie'
  },
  'intl': {
    'en': '*Worldwide',
    'el': '*Διεθνώς',
    'bg': '*В целия свят',
    'fr': '*Le monde entier',
    'de': '*Weltweit',
    'de-at': '*Weltweit',
    'es': '*Mundial',
    'it': '*In tutto il mondo',
    'hr': '*Širom svijeta',
    'hu': '*Világszerte',
    'pl': '*Na całym świecie',
    'pt': '*Global',
    'ro': '*La nivel mondial',
    'ru': '*По всему миру',
    'sk': '*Celý svet',
    'sl': '*Po vsem svetu',
    'cs': '*Celý svět'
  }
};

export const DECLARATION = 'I accept that my personal data will be handled solely for the purpose of processing my enquiry and the provision of marketing material, in full compliance with the BIZ Privacy Policy and GDPR regulations.';

export const FORM_SUBMISSION_TIMEOUT = 7; // seconds

export const GOOGLE_TAG_MANAGER_ID = 'G-QG70TDW9BR';

export const LANGUAGE_CODE_DEFAULT = 'en';
export const LANGUAGE_CODES_SUPPORTED = [
  'en',
  'el',
  'bg',
  'fr',
  'de',
  'de-at',
  'es',
  'it',
  'hr',
  'hu',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'cs'
];

export const MESSAGE_MIN_LENGTH = 10;

export const OPTIONS_PARCEL_SIZE = [
  {
    label: 'Small Parcel',
    value: 'small'
  },
  {
    label: 'Medium Box',
    value: 'medium'
  },
  {
    label: 'Large Box',
    value: 'large'
  },
  {
    label: 'Envelope / Document',
    value: 'envelope'
  },
  {
    label: 'Cargo / Pallet',
    value: 'cargo'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const OPTIONS_TECHNOLOGY = [
  {
    label: 'User-friendly Application',
    value: 'desktop-app'
  },
  {
    label: 'Web Services / API',
    value: 'web-services-api'
  },
  {
    label: 'eCommerce Integrations',
    value: 'biz-link'
  }
];

export const OPTIONS_SHIPMENT_TYPE = [
  {
    label: 'Envelope',
    value: 'Envelope'
  },
  {
    label: 'Package',
    value: 'Package'
  },
  {
    label: 'Pallet',
    value: 'Pallet'
  }
];

export const OPTIONS_VOLUME_ESTIMATE = [
  {
    label: '1 - 100',
    value: '1-100'
  },
  {
    label: '100 - 500',
    value: '100-500'
  },
  {
    label: '500 - 1500',
    value: '500-1500'
  },
  {
    label: '1500 - 5k',
    value: '1500-5k'
  },
  {
    label: '5k - 10k',
    value: '5k-10k'
  },
  {
    label: '10k+',
    value: '10k+'
  }
];

export const OPTIONS_WAREHOUSE_LOCATION = [
  {
    label: 'BIZ Athens',
    value: 'BIZ-HQ',
    countryCode: 'gr'
  },
  {
    label: 'BIZ Leipzig',
    value: 'DE-LZ',
    countryCode: 'de'
  },
  {
    label: 'BIZ Budapest',
    value: 'HU-NT',
    countryCode: 'hu'
  },
  {
    label: 'BIZ Valencia',
    value: 'ES-VL',
    countryCode: 'es'
  },
  {
    label: 'BIZ Portugal',
    value: 'PT-MA',
    countryCode: 'pt'
  },
  {
    label: 'BIZ Bolzano',
    value: 'IT-BZ',
    countryCode: 'it'
  },
  {
    label: 'BIZ Romania',
    value: 'RO-BZ',
    countryCode: 'ro'
  },
  {
    label: 'BIZ Bulgaria',
    value: 'BG-RU',
    countryCode: 'bg'
  },
  {
    label: 'BIZ Nicosia',
    value: 'CY-NI',
    countryCode: 'cy'
  },
  {
    label: 'BIZ Canary Islands',
    value: 'ES-CN',
    countryCode: 'ic'
  }
];

export const PREFERRED_COUNTRY_CODES = [
  'at',
  'bg',
  'cy',
  'cz',
  'de',
  'en',
  'es',
  'fr',
  'gr',
  'hr',
  'hu',
  'it',
  'pl',
  'pt',
  'ro',
  'sk',
  'si',
  'be',
  'lu',
  'nl'

];

export const ABC_OPTIONS_WAREHOUSE_CODES = [
  'gr',
  'de',
  'hu',
  'es',
  'pt',
  'it',
  'ro',
  'bg',
  'cy'
]

export const NETWORK_OPTIONS_WAREHOUSE_CODES = [
  'gr',
  'de',
  'hu',
  'es',
  'it',
  'ro',
  'bg',
  'cy'
]

export const REQUIRED_FIELD_PHONE = (
  true
);

export const PREFERED_BRAND_NAMES = [
  'abcplus'
];


export const HOST_CONFIG = {
  DEFAULT: {
    host: _DEFAULT_HOST,
    hostEu: _DEFAULT_HOST_EU
  },
  ABCPLUS: {
    host: _ABACPLUS_HOST,
    hostEu: _ABACPLUS_HOST
  },
};